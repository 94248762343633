@import "./fonts/fonts.css";

body {
  font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
  padding: 0 !important;
  overflow: visible !important;
  box-sizing: border-box !important;
}

.dd-wrapper {
  width: 100% !important;
}

h2 {
  font-family: "Klavika";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

.column_title {
  font-family: "Klavika";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.text {
  font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.dd-list-item {
  font-size: 18px !important;
}

.dd-header-title {
  font-size: 18px;
}
.MuiButton-contained.Mui-disabled {
  border: 2px solid grey;
}
